<template>
  <v-container class="px-6 d-block" fluid>
    <v-dialog
      v-model="dialog.approval"
      persistent
      no-click-animation
      max-width="400"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2 d-flex align-center">
          <div>
            <v-icon left>mdi-information</v-icon>Konfirmasi Approval
          </div>
          <div class="ml-auto">
            <v-icon size="25" @click="dialog.approval = false;" class="cursop-pointer" color="red">mdi-close-circle-outline</v-icon>
          </div>
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menerima pengajuan program <b>{{ detail.name }}</b
          > oleh publisher <b>{{ detail.user_publisher_name }}</b>?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doApproval"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, Terima
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.reason = true"
            class="text-none flex-grow-1"
          >
            <v-icon left color="#e74c3c">mdi-close-circle-outline</v-icon>
            Tolak dengan Alasan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.reason"
      persistent
      no-click-animation
      max-width="400"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
         Alasan Penolakan Pengajuan
        </v-card-title>
        <v-card-text style="min-height: 80px">
          <div class="subtitle-2">
            Alasan 
            <small style="color: #e74c3c"><i> *wajib diisi</i></small>
          </div>
          <v-form
            ref="form-approval-block"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <v-textarea
              dense
              solo
              rows="5"
              no-resize
              autocomplete="off"
              color="#4ab762"
              type="text"
              placeholder="Tulis disini"
              class="rounded-lg mb-3"
              :disabled="loading"
              v-model="form.reason"
              :error-messages="validation.reason"
              @keyup="validation.reason = ''"
              :rules="[
                (v) => !!v || `Alasan tidak boleh kosong`,
                (v) =>
                  (v && v.length <= 1000) ||
                  'Alasan maksimal 1.000 karakter',
                (v) =>
                  (v && v.length > 25) ||
                  'Alasan minimal 25 karakter',
              ]"
            >
              <template v-slot:message="{ message }">
                <v-tooltip top max-width="250" color="#e74c3c">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="22"
                      class="mr-2 ml-n2"
                      color="#e74c3c"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-alert-circle-outline</v-icon
                    >
                  </template>
                  <span>{{ message }}</span>
                </v-tooltip>
              </template>
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doSave()"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Simpan
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.approval = false; dialog.reason = false; formReset();
              $refs['form-approval'].resetValidation();
              validationReset();"
            width="100"
            class="text-none"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Promo Diskon</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#2ecc71"
                  :disabled="
                    loading ||
                    selected.user.length <= 0 ||
                    selected.user[0].status !== 'waiting'
                  "
                  class="text-none"
                  text
                  small
                  @click="detail = selected.user[0]; dialog.approval = true">
                  <v-icon size="16" color="#2ecc71"> mdi-check-decagram-outline </v-icon>
                  <span class="hidden-sm-and-down ml-1">Terima/Tolak Pengajuan</span>
                </v-btn>

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.user.length <= 0"
                  text
                  small
                  @click="detail = selected.user[0]; dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.user"
              :headers="headers.user"
              :items="dataGrid.user"
              :loading="loading"
              :options.sync="options.user"
              :server-items-length="totalData.user"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.status === 'approved'"
                >
                  Diterima
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: #fb8c00;
                    background-color: #fb8c0024;
                  "
                  v-if="item.status === 'waiting'"
                >
                  Menunggu Konfirmasi
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-if="item.status === 'rejected'"
                >
                  Ditolak
                </v-chip>
              </template>

              <template v-slot:[`item.item_type`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.item_type == 'category'"
                >
                  Kategori Tertentu
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.item_type == 'publisher'"
                >
                  Publisher Tertentu
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.item_type == 'organization'"
                >
                  Organisasi Tertentu
                </v-chip>
              </template>

              <template v-slot:[`item.platform`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.platform == 'mobile'"
                >
                  Aplikasi Mobile
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.platform == 'web'"
                >
                  Aplikasi Web
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(149 165 166);
                    background-color: rgb(149 165 166 / 10%);
                  "
                  v-if="item.platform == 'semua'"
                >
                  Semua Aplikasi
                </v-chip>
              </template>

              <!-- <template v-slot:[`item.percent`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                >
                  <v-icon small left color="rgb(52 152 219)">mdi-sale</v-icon
                  >{{ item.percent + "%" }}
                </v-chip>
              </template> -->

              <template v-slot:[`item.user_publisher_name`]="{ item }">
                {{ item.user_publisher_name === '' ? '-' : item.user_publisher_name }}
              </template>
              <template v-slot:[`item.input_datetime`]="{ item }">
                {{ item.input_datetime | datetime | emptyData }}
              </template>
              <template v-slot:[`item.course_name`]="{ item }">
                <div class="d-flex align-center py-2">
                  <v-avatar size="40" class="rounded">
                    <v-img
                      :src="item.course_img_url">
                    </v-img>
                  </v-avatar>
                  <div class="ml-2">
                    {{ item.course_name }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.start_datetime`]="{ item }">
                {{ item.start_datetime | datetime | emptyData }}
              </template>
              <template v-slot:[`item.end_datetime`]="{ item }">
                {{ item.end_datetime | datetime | emptyData }}
              </template>
              <!-- <template v-slot:[`item.input_datetime`]="{ item }">
                {{ item.input_datetime | datetime | emptyData }}
              </template> -->
              <!-- <template v-slot:[`item.update_datetime`]="{ item }">
                {{ item.update_datetime | datetime | emptyData }}
              </template> -->

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    fetchData();
                    selected.user = [];
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.user"
      v-if="dialog.user"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title class="subtitle-1 px-4 pt-4" style="color: #4ab762"
          >Jadikan User Sebagai Publisher</v-card-title
        >
        <v-card-subtitle class="caption grey--text text--darken-1 px-4 py-1"
          >Lengkapi form dibawah ini</v-card-subtitle
        >
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 120px)" class="py-4 px-4">
          <v-form
            ref="form-user"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <div class="subtitle-2 font-weight-bold">
              Nama Lengkap
              <small style="color: #3498db"><i>tidak dapat diubah</i></small>
            </div>
            <v-text-field
              solo
              color="#4ab762"
              type="text"
              prepend-inner-icon="mdi-account-outline"
              class="rounded-lg mb-3"
              disabled
              v-model="form.name"
              readonly
            >
            </v-text-field>

            <div class="subtitle-2 font-weight-bold mt-4">
              Pilih Organisasi
              <small style="color: #e74c3c"
                ><i> *wajib minimal 1 organisasi</i></small
              >
            </div>
            <v-autocomplete
              :items="organizationData"
              color="#4ab762"
              item-value="id"
              item-text="name"
              @keyup.delete="deleteOrganization"
              v-model="form.organization_id_array"
              :disabled="loading"
              solo
              prepend-inner-icon="mdi-account-group-outline"
              class="rounded-lg mb-3"
              :error-messages="validation.organization_id_array"
              @keyup="validation.organization_id_array = ''"
              placeholder="Pilih disini"
              multiple
            >
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Jadikan Publisher
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.user = false;
              formReset();
              $refs['form-user'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- detail -->
    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Pengajuan Program diskon
          <div class="caption grey--text text--darken-1">
            Informasi Data Pengajuan Program diskon
          </div></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-4"
          v-if="Object.keys(detail).length > 0"
        >
          <div>
            <div class="mb-3">
              <div class="caption mb-1">Nama Publisher</div>
              <div class="subtitle-2 font-weight-bold d-flex align-center">
                <div>
                  <v-avatar tile class="rounded" size="30" color="#ecf0f1">
                    <span
                      v-if="detail.user_publisher_img_url == ''"
                      style="font-size: 11px"
                      >{{ detail.user_publisher_name | initial }}</span
                    >
                    <v-img v-else :src="detail.user_publisher_img_url"></v-img>
                  </v-avatar>
                </div>
                <div class="ml-2">
                  {{ detail.user_publisher_name }}
                </div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Nama Program Diskon</div>
              <div class="subtitle-2 font-weight-bold">
                {{ detail.name }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Tanggal Pengajuan</div>
              <div class="subtitle-2 font-weight-bold">
                {{ detail.input_datetime | datetime | emptyData }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Status Pengajuan</div>
              <div class="subtitle-2 font-weight-bold">
                <div
                  style="
                    color: rgb(46 204 113);
                  "
                  v-if="detail.status === 'approved'"
                >
                  Diterima
                </div>
                <div
                  style="
                    color: #fb8c00;
                  "
                  v-if="detail.status === 'waiting'"
                >
                  Menunggu Konfirmasi
                </div>
                <div
                  style="
                    color: rgb(231, 76, 60);
                  "
                  v-if="detail.status === 'rejected'"
                >
                  Ditolak
                </div>
              </div>
            </div>
            <div class="my-3" v-if="detail.status === 'rejected'">
              <div class="caption">Alasan Ditolak</div>
              <div class="subtitle-2 font-weight-bold" style="color: rgb(231, 76, 60);">
                {{ detail.rejected_reason }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Diskon</div>
              <div class="subtitle-2 font-weight-bold">
                {{ detail.percent }}%
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Berlaku Untuk</div>
              <div class="subtitle-2 font-weight-bold">
                <div
                  style="
                    color: rgb(241 196 15);"
                  v-if="detail.item_type == 'category'"
                >
                  Kategori Tertentu
                </div>
                <div
                  style="
                    color: rgb(46 204 113); "
                  v-if="detail.item_type == 'publisher'"
                >
                  Publisher Tertentu
                </div>
                <div
                  style="
                    color: rgb(52 152 219);"
                  v-if="detail.item_type == 'organization'"
                >
                  Organisasi Tertentu
                </div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Massa Berlaku Diskon</div>
              <div class="subtitle-2 font-weight-bold">
                Berlaku dari {{ detail.start_datetime | datetime }}, maksimal {{ detail.end_datetime | datetime }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Kelas yang Diajukan</div>
              <div class="subtitle-2 font-weight-bold">
                <div>
                    <v-list flat three-line class="py-0">
                      <v-list-item class="px-0">
                        <v-list-item-avatar tile size="70" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="detail.course_img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="'body-2'">
                            {{ detail.course_name }}  
                          </v-list-item-subtitle>

                          <v-list-item class="px-0" style="min-height: 20px;">
                            <v-list-item-avatar :size="10" class="mt-2 mb-0 " style="margin-right: 8px;">
                              <v-img :src="detail.course_organization_img_url" v-if="detail.course_organization_img_url !== ''"></v-img>
                              <v-icon v-if="detail.course_organization_img_url === ''" size="10">mdi-office-building</v-icon>
                            </v-list-item-avatar>
                
                            <v-list-item-content class="pt-1 pb-0">
                              <v-list-item-title class="text-second caption" :class="''">{{ detail.course_organization_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <p class="text-first font-weight-bold text-capitalize mt-n1 mb-0" :class="'body-2'">
                            <!-- <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(detail.course_price) > 0">
                              {{ detail.course_price | price }}
                            </span> -->
                            <span class="font-weight-bold red--text">
                              <span style="color: rgb(46 204 113)" v-if="parseInt(detail.course_price) > 0">
                                {{ parseInt(detail.course_price) | price }}
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </p>

                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!-- <div>
                      <v-alert
                        dense
                        text
                        color="success">
                        <div class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="'body-1'">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.price) > 0 && parseInt(detail.percent) > 0">
                            {{ detail.course_price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(detail.course_price) > 0 && parseInt(detail.percent) > 0">
                            (<span v-if="parseInt(detail.course_discount_percent_publisher) > 0">{{ parseInt(detail.course_discount_percent_publisher) }}%+</span>{{ parseInt(detail.percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(detail.course_price) > 0">
                              {{ parseInt(detail.course_price_discount) - (parseInt(detail.course_price_discount)*parseInt(detail.percent))/100 | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div>
                        <div class="caption">
                          Harga Setelah Mengikuti Potongan
                        </div>
                      </v-alert>

                    </div> -->

                  </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="changeNameRouter(); dialog.detail = false"
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
          <v-btn
            color="#2ecc71"
            outlined
            v-if="detail.status === 'waiting'"
            depressed
            rounded
            @click="dialog.approval = true"
            class="text-none flex-grow-1"
          >
            Terima/Tolak Pengajuan
          </v-btn>
          <!-- <v-btn
                  color="#2ecc71"
                  :disabled="
                    loading ||
                    selected.user.length <= 0 ||
                    selected.user[0].status !== 'waiting'
                  "
                  class="text-none"
                  text
                  small
                  @click="detail = selected.user[0]; dialog.approval = true">
                  <v-icon size="16" color="#2ecc71"> mdi-check-decagram-outline </v-icon>
                  <span class="hidden-sm-and-down ml-1">Terima/Tolak Pengajuan</span>
                </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ApprovalPromo",

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Promo Diskon",
        disabled: true,
        href: "/promo-discount",
      },
    ],

    loading: false,
    dialog: {
      user: false,
      approval: false,
      detail: false,
      reason: false
    },
    selected: {
      user: [],
    },
    detail: {},
    headers: {
      user: [
        {
          text: "NAMA PUBLISHER",
          width: "200",
          align: "start",
          sortable: true,
          value: "user_publisher_name",
        },
        {
          text: "NAMA DISKON",
          width: "300",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "NAMA KELAS",
          width: "300",
          align: "start",
          sortable: true,
          value: "course_name",
        },
        {
          text: "STATUS PENGAJUAN",
          width: "180",
          sortable: true,
          align: "center",
          value: "status",
        },
        {
          text: "TGL PENGAJUAN",
          width: "180",
          align: "start",
          sortable: true,
          value: "input_datetime",
        },
        {
          text: "BERLAKU UNTUK",
          width: "250",
          align: "center",
          sortable: true,
          value: "item_type",
        },
        {
          text: "PLATFORM",
          width: "180",
          align: "center",
          sortable: true,
          value: "platform",
        },
        // {
        //   text: "DISKON",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "percent",
        // },
        {
          text: "TGL MULAI",
          width: "180",
          sortable: true,
          align: "center",
          value: "start_datetime",
        },
        {
          text: "TGL SELESAI",
          width: "180",
          sortable: true,
          align: "center",
          value: "end_datetime",
        },
        // {
        //   text: "TGL INPUT",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "input_datetime",
        // },
        // {
        //   text: "TERAKHIR DIUBAH",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "update_datetime",
        // },
      ],
    },
    dataGrid: {
      user: [],
    },
    options: {
      user: {},
    },
    totalData: {
      user: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    valid: true,
    form: {
      reason: ""
    },
    validation: {
      reason: ""
    },

    organizationData: [],
  }),

  watch: {
    "options.user": {
      async handler() {
        this.selected.user = [];
        await this.fetchData();
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    formReset() {
      Object.assign(this.form, {
        reason: ""
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        reason: ""
      });
    },
    setForm() {
      let selected = this.selected.user[0];
      Object.assign(this.form, {
        id: selected.id,
        name: selected.name,
        organization_id_array: ["1"],
      });
    },

    async fetchData() {
      this.loading = true;

      let sign =
        this.options.user.sortDesc && this.options.user.sortDesc[0] ? "-" : "";

      let params = {
        page: this.options.user.page,
        limit: this.options.user.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.user.sortBy.length > 0 &&
        this.options.user.sortDesc.length > 0
      ) {
        params.sort =
          sign + (this.options.user.sortBy && this.options.user.sortBy[0]);
      }

      let response = await this.$get("admin/discount_publisher", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.user = response.results.data;
        this.totalData.user = parseInt(response.results.pagination.total_data);
        if (this.$route.query.data !== undefined) {
          for (let index = 0; index < response.results.data.length; index++) {
            if(response.results.data[index].discount_id === this.$route.query.data) {
              this.detail = response.results.data[index]
              this.dialog.detail = true
            }
            
          }
        }
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async doApproval() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menerima pengajuan diskon...";

      let response = await this.$post("admin/discount_publisher/approval", 
      {
        id: this.detail.id,
        approve_bool: true,
        reject_reason: ""
      });

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.dialog.approval = false
        this.detail.status = 'approved'
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil Menerima pengajuan diskon",
          color: "#2ecc71",
        };
        this.dialog.confirmDelete = false;

        this.selected.promo = [];
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },

    async doSave() {
      console.log(this.$refs["form-approval-block"].validate())
      let isValid = this.$refs["form-approval-block"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menolak pengajuan diskon...";

        let response = await this.$post("admin/discount_publisher/approval", 
        {
          id: this.detail.id,
          approve_bool: false,
          reject_reason: this.form.reason
        });

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.dialog.approval = false
          this.dialog.reason = false
          this.detail.status = 'rejected'
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil Menolak pengajuan diskon",
            color: "#2ecc71",
          };
          this.formReset();
          this.$refs["form-approval-block"].resetValidation();

          this.validationReset();
          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    changeNameRouter() {
      if (this.$route.query.data !== undefined) {
        this.$router.replace({ path: '/approval-promo-discount' })
      }
    },
  },
};
</script>

<style>
</style>